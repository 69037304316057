<template>
  <div class="container header__container">
    <router-link
      to="/"
      class="header__logo"
      v-if="header.logo"
      @click="menuClose"
    >
      <v-lazy-image
        :src="header.logo[0]['url']"
        rel="preload"
        width="71"
        height="71"
        alt="logo"
      />
    </router-link>
    <div class="header__search">
      <button class="header__search--btn" @click="$emit('showFormSearch')">
        search
      </button>
    </div>

    <a class="header__menu--kz" href="https://iitt.kz/" target="_blank">{{
      $t("OUR REPRESENTATION")
    }}</a>

    <button class="header__burger" aria-label="burger" v-on:click="menuBtn">
      burger
      <i class="header__burger--icon"></i>
      <i class="header__burger--icon"></i>
    </button>

    <div class="header__collapse">
      <div class="header__collapse--inner">
        <nav class="header__menu">
          <div class="header__collapse--home">
            <router-link to="/" class="header__menu--home" @click="menuBtn">
              <img
                src="@/assets/images/home.svg"
                width="17"
                height="16"
                alt="home"
              />
            </router-link>
            <lang-switcher></lang-switcher>
          </div>
          <ul class="header__menu--list menu--list" v-if="services && menu">
            <li
              class="menu--list__item"
              v-for="(nesting_0, index) in services"
              :key="nesting_0.id"
            >
              <router-link
                class="menu--list__link"
                :class="{
                  hover: nesting_0.children.length > 0,
                  show: index == show,
                }"
                @click="menuBtn"
                :to="'/' + nesting_0.slug"
                >{{
                  getTranslatedName(nesting_0.translations, $i18n.locale)
                }}</router-link
              >
              <ul
                class="menu--list__dropdown dropdown--list"
                v-if="nesting_0.children.length > 0"
              >
                <li
                  class="dropdown--list__item"
                  v-for="nesting_1 in nesting_0.children"
                  :key="nesting_1.id"
                >
                  <router-link
                    v-if="nesting_0.slug === 'komplektuyuci'"
                    class="dropdown--list__link"
                    :to="'/' + nesting_0.slug + '/' + nesting_1.slug"
                    @click="menuBtn"
                    >{{
                      getTranslatedName(nesting_1.translations, $i18n.locale)
                    }}</router-link
                  >
                  <router-link
                    v-else
                    class="dropdown--list__link"
                    :to="'/' + nesting_1.slug"
                    @click="menuBtn"
                    >{{
                      getTranslatedName(nesting_1.translations, $i18n.locale)
                    }}</router-link
                  >
                </li>
              </ul>
            </li>
            <!--Pages menu-->
            <li
              class="menu--list__item"
              v-for="nesting_0 in menu"
              :key="nesting_0.id"
            >
              <router-link
                class="menu--list__link"
                :class="{ hover: nesting_0.children.length > 0 }"
                :to="nesting_0.url"
                @click="menuBtn"
                >{{
                  getTranslatedTitle(nesting_0.translations, $i18n.locale)
                }}</router-link
              >
              <ul
                class="menu--list__dropdown dropdown--list"
                v-if="nesting_0.children.length > 0"
              >
                <li
                  class="dropdown--list__item"
                  v-for="nesting_1 in nesting_0.children"
                  :key="nesting_1.id"
                >
                  <router-link
                    class="dropdown--list__link"
                    :to="nesting_1.url"
                    >{{
                      getTranslatedTitle(nesting_1.translations, $i18n.locale)
                    }}</router-link
                  >
                </li>
              </ul>
            </li>
          </ul>

          <div class="header__collapse--wrap">
            <div class="header__social">
              <a
                class="header__social--link fb"
                :href="header.facebook"
                target="_blank"
                >facebook</a
              >
              <a
                class="header__social--link in"
                :href="header.linkedin"
                target="_blank"
                >linkedin</a
              >
            </div>
            <div class="header__call--number">
              <a :href="'tel: +38 ' + header.tel">
                <span>{{ header.tel }}</span>
                <span>{{ t("telephone") }}</span>
              </a>
              <span v-if="translations[$i18n.locale]">{{
                getField("country", $i18n.locale)
              }}</span>
            </div>

            <div class="header__call">
              <a
                href="#callBack"
                class="header__call--back"
                @click="modalToggle"
                :class="{ openPopup: showModal }"
                >Зворотній дзвінок</a
              >

              <transition name="popup">
                <Popup v-if="showModal" @click="showModal = false" />
              </transition>
            </div>
          </div>
        </nav>
      </div>
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from "vue";

const Popup = defineAsyncComponent(() => import("@/components/Popup.vue"));
const LangSwitcher = defineAsyncComponent(() =>
  import("@/components/UI/LangSwitcher")
);
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import VLazyImage from "v-lazy-image";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
  components: {
    Popup,
    VLazyImage,
    LangSwitcher,
  },
  props: {
    header: {
      type: Array,
      required: true,
    },
    menu: {
      type: Array,
      required: true,
    },
    translations: {
      type: Array,
      required: true,
    },
    services: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      showModal: false,
      showForm: false,
      show: 0,
    };
  },
  methods: {
    menuBtn: function () {
      this.$emit("menu-btn");
    },
    menuClose: function () {
      this.$emit("menu-close");
    },
    modalToggle: function (e) {
      e.preventDefault();
      this.showModal = !this.showModal;
    },
    hideMenu: function () {},
    getTranslations(locale) {
      return this.translations[locale];
    },
    getField(fieldName, locale) {
      return this.getTranslations(locale)[fieldName];
    },
    getTranslatedTitle(transArray, locale) {
      let title = "";
      transArray.forEach((element) => {
        if (element.locale === locale) {
          title = element.title;
        }
      });
      return title;
    },
    getTranslatedName(transArray, locale) {
      let name = "";
      transArray.forEach((element) => {
        if (element.locale === locale) {
          name = element.name;
        }
      });
      return name;
    },
  },
});
</script>

<style lang="scss" scoped>
@import "~@/assets/styles/_mixins.scss";
.header {
  &__container {
    display: flex;
    align-items: center;
  }

  &__logo {
    width: 38px;
    height: 38px;
    flex: 0 0 auto;
    margin-right: 15px;

    img {
      display: block;
    }
  }

  &__menu {
    position: relative;
    z-index: 3;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;

    &::before {
      content: "";
      position: absolute;
      top: -30px;
      left: -15px;
      width: calc(100% + 30px);
      height: calc(100% + 120px);
      z-index: 1;
      background: rgba(#1c1c1c, 0.7);
    }

    > * {
      position: relative;
      z-index: 3;
    }

    &--kz {
      position: relative;
      display: inline-flex;
      align-items: center;
      font-size: 10px;
      letter-spacing: 0.5px;
      margin-left: 15px;
      color: #afafaf;
      text-transform: uppercase;
      text-decoration: none !important;
      padding-left: 37px;
      margin-right: auto;

      @media (max-width: 410px) {
        font-size: 0;
      }

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 28px;
        height: 17px;
        background: url("~@/assets/images/kazakhstan.jpg") center center / 100%
          100% no-repeat;
      }
    }

    &--home {
      width: 17px;
      height: 16px;
      flex: 0 0 auto;
      margin-bottom: 2px;

      img {
        display: block;
      }
    }
  }

  &__search {
    display: flex;
    padding: 5px 15px;

    &--btn {
      position: relative;
      font-size: 0;
      width: 23px;
      height: 23px;
      background: url("~@/assets/images/search.svg") no-repeat;
      background-size: 100% 100%;

      &::before {
        content: "";
        position: absolute;
        bottom: -12px;
        left: 0;
        width: 0;
        height: 3px;
        background-color: #f9b80e;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s linear;
      }
    }
  }

  &__call {
    width: 100%;
    margin-top: 20px;
    &--number {
      display: flex;
      align-items: center;

      a {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 20px;
        margin-right: 10px;
        line-height: calc(26 / 20);
        text-decoration: none !important;

        &::before {
          content: "";
          display: block;
          width: 16px;
          height: 16px;
          flex: 0 0 auto;
          margin-right: 15px;
          background: url("~@/assets/images/phone-icon.svg") no-repeat;
          background-size: 100% 100%;
        }

        @media (hover: hover) {
          &:hover {
            span {
              &:nth-of-type(1) {
                opacity: 0;
                visibility: hidden;
              }

              &:nth-of-type(2) {
                opacity: 1;
                visibility: visible;
              }
            }
          }
        }

        span {
          transition: all 0.3s linear;
          &:nth-of-type(2) {
            position: absolute;
            top: 50%;
            left: 31px;
            transform: translateY(-50%);
            opacity: 0;
            visibility: hidden;
            color: #f9b80e;
          }
        }
      }

      > span {
        font-size: 20px;
        line-height: calc(26 / 20);
      }
    }

    &--back {
      position: relative;
      display: inline-flex;
      align-items: center;
      border-bottom: 1px solid #f9b80e;
      font-size: 20px;
      line-height: calc(26 / 20);
      color: #f9b80e;

      &::after {
        content: "";
        display: block;
        width: 8px;
        height: 8px;
        flex: 0 0 auto;
        margin-left: 10px;
        background-color: #f9b80e;
        mask-image: url("~@/assets/images/arrow_call.svg");
        mask-repeat: no-repeat;
        mask-size: 100% 100%;
        transition: background 0.3s linear;
      }

      @media (hover: hover) {
        &:hover {
          text-decoration: none;
          color: #fff;
          border-color: #fff;

          &::after {
            background-color: #fff;
          }
        }
      }
    }
  }

  &__social {
    display: flex;
    align-items: center;
    margin-right: 25px;
    margin-bottom: 15px;
    margin-top: 15px;

    &--link {
      display: block;
      width: 24px;
      height: 24px;
      font-size: 0;
      background-color: #f9b80e;
      mask-repeat: no-repeat;
      mask-size: 100% 100%;

      &:not(:last-child) {
        margin-right: 18px;
      }

      &.fb {
        mask-image: url("~@/assets/images/fb.svg");
      }

      &.in {
        mask-image: url("~@/assets/images/in.svg");
      }

      @media (hover: hover) {
        &:hover {
          background-color: #fff;
        }
      }
    }
  }

  &__lang {
    display: flex;
    align-items: center;
    border: 1px solid #707070;
    padding: 5px 15px;

    margin: 0 65px 0 45px;
    @include property("margin-right", 45, 15, true, 1024, 576);
    @include property("margin-left", 65, 15, true, 1024, 576);

    &--link {
      text-decoration: underline;
      color: #f9b80e;

      &:nth-of-type(n + 2) {
        margin-left: 15px;
      }

      &.active {
        color: #929292;
        text-decoration: none !important;
        pointer-events: none;
        cursor: none;
      }

      @media (hover: hover) {
        &:hover {
          color: #fff;
        }
      }
    }
  }

  &__burger {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    width: 36px;
    height: 36px;
    font-size: 0;

    &--icon {
      display: block;
      height: 3px;
      background: #f9b80e;
      transition: all 0.3s linear;

      &:nth-of-type(1) {
        width: 100%;

        .openMenu & {
          transform: rotate(45deg) translate(10%, 135%);
        }
      }

      &:nth-of-type(2) {
        width: 78%;
        margin-top: 8px;

        .openMenu & {
          width: 100%;
          transform: rotate(-45deg) translate(9%, -135%);
        }
      }
    }
  }

  &__collapse {
    position: fixed;
    top: 68px;
    bottom: 100%;
    right: 0;
    left: 0;
    z-index: 99;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s linear;

    background: rgba(#1c1c1c, 0.75);
    backdrop-filter: blur(10px);

    &--inner {
      position: relative;
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      max-width: 768px;
      width: 100%;
      padding: 30px 15px;
      overflow-y: auto;
      background: url("~@/assets/images/menu-bg.jpg") no-repeat;
      background-size: cover;
    }

    &--wrap {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-top: auto;
    }

    &--home {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .openMenu & {
      opacity: 1;
      visibility: visible;
      bottom: 0;
    }
  }
}

.menu--list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;

  &__item {
    @media (hover: hover) {
      &:hover {
        .dropdown--list {
          opacity: 1;
          visibility: visible;
          transform: translateX(0);
          pointer-events: all;
        }

        .menu--list__link.hover {
          &::before {
            width: 100%;
            visibility: visible;
            opacity: 1;
          }
        }
      }
    }

    &:nth-of-type(-n + 2) {
      > a {
        font-family: "PT Sans";
        font-size: 18px;
        line-height: calc(23 / 18);
        letter-spacing: 0.9px;
        color: #f9b80e;
        text-transform: uppercase;
      }
    }
  }

  &__link {
    font-weight: 300;
    font-size: 1rem;
    line-height: calc(20 / 16);
    font-family: "Roboto";
    color: #aaaaaa;
    padding-top: 12px;
    padding-bottom: 12px;
    border-bottom: 3px solid transparent;
    text-decoration: none !important;

    &.hover {
      position: relative;

      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 3px;
        bottom: 0;
        left: 0;
        background-color: #f9b80e;
        visibility: hidden;
        opacity: 0;
        transition: all 0.3s linear;
      }
    }
  }
}

.dropdown--list {
  margin-bottom: 20px;
  &__item {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__link {
    position: relative;
    display: inline-flex;
    align-items: center;
    font-size: 18px;
    line-height: calc(23 / 18);
    letter-spacing: 0.9px;
    color: #f9b80e;

    &::after {
      content: "";
      display: block;
      width: 11px;
      height: 11px;
      flex: 0 0 auto;
      background: url("~@/assets/images/arrow_menu.svg") no-repeat;
      background-size: 100% 100%;
    }
  }
}

//// анимация появления
.popup-enter-from,
.popup-leave-to {
  opacity: 0;
}

.popup-enter-active .popup-container,
.popup-leave-active .popup-container {
  opacity: 1;
}
</style>
